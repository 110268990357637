const faq = [
  {
    question: 'What is Fisherman?',
    answer:
      'Fisherman is a website business that uses proprietary automation technology to create beautiful websites for businesses in minutes, without any effort required by the business owner. We exist to help business owners that are too busy to manage their own online presence and don’t want to spend a lot of money to have someone else do it. We work with businesses across the US to help them build their existing relationships and reach new customers.',
  },
  {
    question: 'Why are you doing this?',
    answer:
      'Our mission is to help small businesses compete online, and small businesses need extra support right now. We’ve built software that can create and manage complete websites automatically, and we feel a responsibility to help however we can.',
  },
  {
    question: 'What do I need to do?',
    answer:
      'Simply fill out the form above. We’ll call to ask some basic questions about your business, and then we’ll take care of building and managing your website and Google profile.',
  },
  {
    question: 'How does it work?',
    answer:
      'We have built software that transforms business information into a complete website in minutes. Since the process is automated, we can create and update websites quickly with 0 effort by the small business owner. We have a dedicated support team to help with any edits you want to make on your website or other digital marketing services.',
  },
  {
    question: 'What’s included in the package?',
    answer:
      'The package includes web hosting, a custom domain, a modern mobile-optimized website, SSL security, and access to our website editor. ',
  },
];

export default faq;
