import React from 'react';

import { Grid, Image, Header, Accordion } from 'semantic-ui-react';

import CustomerSignUpForm from '../../components/ContactForm/CustomerSignUpForm';
import SEO from '../../components/SEO';
import FAQ from '../../data/content/coronavirus-faq';
import Layout from '../../layouts/SiteLayout';

import ChowNowLogo from '../../data/logos/ChowNow.png';
import FishermanLogo from '../../data/logos/FishermanRedTextLogo.svg';

import '../../css/pages/coronavirus-support.css';

export default () => (
  <Layout
    additionalLinks={[
      {
        title: 'Get Website',
        to: '/get-website/',
        internal: true,
        isButton: true,
      },
    ]}
  >
    <SEO
      title="Fisherman and ChowNow"
      description="Fisherman is teaming up with ChowNow to provide modern websites for free with ChowNow online ordering to restaurants."
    />
    <Grid id="coronavirus-support" stackable>
      <Grid.Column width={16} textAlign="center">
        <Image src={ChowNowLogo} className="brand-logo" />
        <Image src={FishermanLogo} className="brand-logo" />
      </Grid.Column>
      <Grid.Column width={8}>
        <Header as="h1">Get a Modern Website</Header>
        <Header as="h3">1 Month Free Trial</Header>
        <p>
          Fisherman is proud to partner with ChowNow to offer complete website packages for your
          restaurant. These websites can be connected directly to your ChowNow online ordering page.
          As soon as you submit your form, we&apos;ll build a custom website within 24 hours for you
          to preview.
        </p>
        <p>Websites include:</p>
        <ol>
          <li>Custom Domain</li>
          <li>Hosting</li>
          <li>Mobile-friendly design</li>
          <li>SEO best practices</li>
        </ol>
        <p>Submit your contact information and we’ll get started immediately.</p>
      </Grid.Column>
      <Grid.Column width={8}>
        <CustomerSignUpForm referralSource="ChowNow" />
      </Grid.Column>
      <Grid.Column width={16}>
        <div className="q-and-a">
          <Header as="h1" style={{ textAlign: 'center' }}>
            FAQ&apos;s
          </Header>
          <Accordion
            styled
            panels={FAQ.map(({ question, answer }) => ({
              key: question,
              title: {
                content: <Header as="h4">{question}</Header>,
              },
              content: answer,
            }))}
          />
        </div>
      </Grid.Column>
    </Grid>
  </Layout>
);
